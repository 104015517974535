/**
 * IMPORTS
 */
import {Alert} from 'antd';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {faLock} from '@fortawesome/free-solid-svg-icons';
import {faUser} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {Checkbox} from 'antd';
import {Input} from 'antd';
import {notification} from 'antd';
import {Tooltip} from 'antd';
import {changeLanguage} from 'i18next';
import React from 'react';
import {useCallback} from 'react';
import {useEffect} from 'react';
import {useRef} from 'react';
import {useState} from 'react';
import {getI18n} from 'react-i18next';
import {useTranslation} from 'react-i18next';
import {AiOutlineLoading} from 'react-icons/ai';
import * as userAPI from 'src/aggregates/user/api';
import IconWrapper from 'src/components/Shared/IconWrapper';
import {LANGUAGES} from 'src/components/Shared/LanguageSwitcher/constants';
import history from 'src/history';
import Store from 'src/store';
import * as Yup from 'yup';

import 'src/components/Login/style.scss';


/**
 * TYPES
 */
import {loginErrors} from 'src/aggregates/user/state.d';
import {CheckboxChangeEvent} from 'antd/lib/checkbox';
import {ILanguage} from 'src/components/Shared/LanguageSwitcher/index.d';


/**
 * CONSTANTS AND DEFINITIONS
 */
const ERROR_MESSAGES: Record<loginErrors, string> = {
    authentication: 'Usuário ou senha inválidos',
    authorization: 'Usuário sem permissão para prosseguir na posição informada',
    unavailable:  'Serviço indisponível no momento, tente novamente mais tarde',
    unknown: 'Serviço indisponível no momento, tente novamente mais tarde',
};


/**
 * CODE
 */
function Form (): JSX.Element
{
    const [user, setUser] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const inputRef = useRef<Input|null>(null);
    const [userRole, setUserRole] = useState<string>(
        localStorage.getItem('@pecazap:role') || 'agents',
    );
    const [isOnline, setIsOnline] = useState<boolean>(window.navigator.onLine);
    const [language, setLanguage] = useState('');

    // get translate function
    const {t} = useTranslation();

    /**
     * I update network status with the current navigator connection status
     *
     * :returns: nothing
     */
    const updateNetwork = useCallback((): void =>
    {
        setIsOnline(window.navigator.onLine);
    }, []);


    /**
     * I check when connection status change
     *
     * :returns: nothing
     */
    useEffect(() =>
    {
        // add event listener to check network status
        window.addEventListener('offline', updateNetwork);
        window.addEventListener('online', updateNetwork);

        // clear event listener on component unmount
        return () =>
        {
            window.removeEventListener('offline', updateNetwork);
            window.removeEventListener('online', updateNetwork);
        };
    }, [updateNetwork]);


    /**
     * I listen for user role changes.
     *
     * :returns: nothing
     */
    useEffect((): void =>
    {
        // store user role to local storage
        localStorage.setItem('@pecazap:role', userRole);
    }, [userRole]);


    /**
     * I monitor errors in filling the login form.
     *
     * :returns: nothing
     */
    useEffect(() =>
    {
        // error message is set: display it
        if (errorMessage !== '')
        {
            // show error message
            notification.error({
                description: errorMessage,
                message: t('Erro'),
                placement: 'bottomLeft',
            });

            // reset error message flag
            setErrorMessage('');
        }
    }, [errorMessage]);


    /**
     * I handle component mount.
     *
     * :returns: nothing
     */
    useEffect(() =>
    {
        // set the focus on user input when the component is rendered
        inputRef?.current?.focus();

        // get translations props
        const i18n = getI18n();

        // get selected language
        const selected = localStorage.getItem('@pecazap:language') ??
                         i18n.language;

        // language found: save id
        if (LANGUAGES.find(({id}) => id === selected) !== undefined)
        {
            setLanguage(selected);
        }
    }, []);


    /**
     * I handle the supervisor checkbox change.
     *
     * :param event: event triggered by checkbox change
     *
     * :returns: nothing
     */
    function handleCheckbox(event: CheckboxChangeEvent): void
    {
        // checkbox checked: set user role as supervisors
        if (event.target.checked === true)
        {
            setUserRole('supervisors');
        }

        // checkbox not checked: set user role as agents
        else
        {
            setUserRole('agents');
        }
    }


    /**
     * I flag errors during the login process.
     *
     * :returns: promise with login failure flag
     */
    async function hasError (): Promise<boolean>
    {
        // get updated state
        const state = Store.getState();

        // check for login errors
        const loginError = state.user.loginError;

        // login error: handle it
        if (loginError !== null)
        {
            // set login error message
            setErrorMessage(t(ERROR_MESSAGES[loginError]));

            // flag error
            return true;
        }

        // no errors found: report success
        return false;
    }


    /**
     * I validate the user input.
     *
     * :returns: validation outcome
     */
    const validation = (): boolean =>
    {
        // set validation schema
        const schema = Yup.object().shape({
            password: Yup.string().required(),
            user: Yup.string().required(),
        });

        // run user input through validation schema
        const isValid = schema.isValidSync({password, user});

        return isValid;
    };


    /**
     * I login the user on application.
     *
     * :returns: promise with nothing
     */
    const doLogin = async (): Promise<void> =>
    {
        // user input is not valid: notify error and return
        if (validation() === false)
        {
            setErrorMessage(t('Usuário ou senha não preenchido'));
            return;
        }

        // make sure it is not redirecting
        setIsLoading(true);

        // trigger user login
        await userAPI.login(user, password, userRole === 'supervisors');

        // successful login: redirect to dashboard
        if (await hasError() === false)
        {
            history.push('/loading');
        }

        // unsuccessful login: reset loading state
        else
        {
            setIsLoading(false);
        }
    };


    /**
     * I bind enter key for login user on application.
     *
     * :returns: promise with nothing
     */
    const handleKeyDown = async (e: KeyboardEventInit): Promise<void> =>
    {
        // not submitted yet and enter pressed: login
        if (e.key === 'Enter' && isLoading === false)
        {
            await doLogin();
        }
    };

    /**
     * I handle change language.
     *
     * :param language: language data
     *
     * :returns: nothing
     */
    function handleChangeLanguage(language: ILanguage): void
    {
        // save selected language on local storage
        localStorage.setItem('@pecazap:language', language.id);

        // change app language
        changeLanguage(language.lang);

        // save local selected language
        setLanguage(language.id);
    }

    /**
     * I get language button classes.
     *
     * :param selected: language data
     *
     * :returns: classes
     */
    function getLanguageClasses(selected: ILanguage): string
    {
        // no language in local storage: return empty string
        if (language === '')
        {
            return '';
        }

        // language is not selected: set hidden class
        if (language !== selected.id)
        {
            return 'hidden';
        }

        // language is selected: set selected class
        if (language === selected.id)
        {
            return 'selected';
        }
    }

    // return form
    return (

        // form div
        <div className="login_form">
            {isOnline === false && (
                <Alert
                    className="connection_alert"
                    message={t('Sem acesso à internet, verifique sua conexão.')}
                    type="error"
                />
            )}

            {/* Username field */}
            <div className="login_input-container">
                <FontAwesomeIcon icon={faUser} />
                <Input
                    disabled={isLoading}
                    placeholder={t('Usuário')}
                    className="form_input"
                    value={user}
                    onChange={e => setUser(e.target.value)}
                    onKeyDown={async e => await handleKeyDown(e)}
                    ref={inputRef}
                />
            </div>

            {/* Password field */}
            <div className="login_input-container">
                <FontAwesomeIcon icon={faLock} />
                <Input.Password
                    disabled={isLoading}
                    placeholder={t('Senha')}
                    className="form_input-password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    onKeyDown={async e => await handleKeyDown(e)}
                />
            </div>

            {/* Login button */}
            <Button
                type="primary"
                block
                className="form_button"
                onClick={async () => await doLogin()}
                disabled={isLoading}
            >
                <span>{t('Entrar')}</span>
                {isLoading === true ? (
                    <IconWrapper className="icon-loading" spin={true}>
                        <AiOutlineLoading />
                    </IconWrapper>
                ) : (
                    <FontAwesomeIcon icon={faArrowRight} />
                )}
            </Button>

            {/* role checkbox */}
            <Checkbox
                className="form_supervisors-checkbox"
                onChange={handleCheckbox}
                checked={userRole === 'supervisors'}
            >
                {t('Entrar como supervisor')}
            </Checkbox>

            <div className='languages'>
                <p>{t('Definir idioma')}:</p>

                {LANGUAGES.map(language => (
                    <Tooltip
                        key={language.id}
                        title={language.name}
                    >
                        <button
                            className={getLanguageClasses(language)}
                            onClick={() => handleChangeLanguage(language)}
                        >
                            <img src={language.image} width={32} />
                        </button>
                    </Tooltip>
                ))}
            </div>
        </div>
    );
}


/**
 * EXPORTS
 */
export default Form;
