/**
 * IMPORTS
 */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import en from 'src/i18n/translations/en.json';
import es from 'src/i18n/translations/es.json';
import pt from 'src/i18n/translations/pt.json';


/**
 * CODE
 */

// configure i18n using language detector
i18n.use(LanguageDetector).use(initReactI18next).init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'pt',
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: {
            translation: en,
        },
        es: {
            translation: es,
        },
        pt: {
            translation: pt,
        },
    },
});


/**
 * EXPORTS
 */
export default i18n;
