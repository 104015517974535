/**
 * IMPORTS
 */
import loadable from '@loadable/component';
import {t} from 'i18next';
import React from 'react';
import LoadingProgress from 'src/components/Shared/LoadingProgress';


/**
 * EXPORTS
 */
export const Admin = loadable(() => import('src/components/Admin'), {
    fallback: (
        <div className="login_loading">
            <LoadingProgress
                start={90}
                percent={100}
                info={t('Redirecionando...')}
            />
        </div>
    ),
});

export const Dashboard = loadable(() => import('src/components/Dashboard'), {
    fallback: (
        <div className="login_loading">
            <LoadingProgress
                duration={10}
                start={90}
                percent={100}
                info={t('Redirecionando...')}
            />
        </div>
    ),
});

export const Loading = loadable(() => import('src/components/Loading'), {
    fallback: (
        <div className="login_loading">
            <LoadingProgress
                percent={0}
                info={t('Iniciando...')}
            />
        </div>
    ),
});
