/**
 * IMPORTS
 */
import brazil from 'src/assets/images/flags/brazil.svg';
import spain from 'src/assets/images/flags/spain.svg';
import unitedStates from 'src/assets/images/flags/united-states.svg';


/**
 * TYPES
 */
import {ILanguage} from 'src/components/Shared/LanguageSwitcher/index.d';


/**
 * CODE
 */
const LANGUAGES: ILanguage[] = [
    {
        id: 'pt-BR',
        image: brazil,
        lang: 'pt-BR',
        name: 'Português (Brasil)',
    },
    {
        id: 'es-419',
        image: spain,
        lang: 'es-419',
        name: 'Español',
    },
    {
        id: 'en-US',
        image: unitedStates,
        lang: 'en-US',
        name: 'English (USA)',
    },
];


/**
 * EXPORTS
 */
export {
    LANGUAGES,
};
